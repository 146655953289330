<template>
  <v-app>
    <v-app-bar
      app
      dense
      dark
    >
      <v-toolbar-title>Wapidu</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn :to="{ name: 'login' }">
        Iniciar Sesión
      </v-btn>

      <v-btn
        color="primary"
        :to="{ name: 'signup' }"
      >
        Pruébalo
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" lg="8">
            <v-img src="@/assets/img/logo_color.png" />
          </v-col>

          <v-col
            cols="12"
            class="text-center"
          >
            <v-btn
              color="primary"
              :to="{ name: 'signup' }"
              x-large
            >
              Pruébalo
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="primary lighten-3 my-8 py-10">
          <v-col
            cols="12"
          >
            <h2 class="text-center white--text">Servicios</h2>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-card>
              <v-card-title>Lavandería</v-card-title>
              <v-card-text>
                Deja que los profesionales hagan por ti el trabajo de lavado y
                doblado de tus prendas textiles.
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-card>
              <v-card-title>Planchado</v-card-title>
              <v-card-text>
                Quitar arrugas y marcas de las prendas ya que gracias al calor
                utilizado en el proceso hará que las fibras no se estiren y
                recuperen su forma inicial (u original) listas para lucir.
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-card>
              <v-card-title>Tintorería</v-card-title>
              <v-card-text>
                Deja que los profesionales se encarguen del desmanchado,
                lavado, secado, planchado o doblado de todas tus textiles,
                total atención a nuestros clientes y a sus prendas textiles.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-footer app>
      Wapidu 2020 &copy;
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'Landing',
};
</script>

<style lang="scss" scoped>

</style>
